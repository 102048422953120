// import Permissions from 'src/security/permissions';
// const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/home',
    loader: () => import('src/view/home/Home'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/frequencies',
    loader: () => import('src/view/sound/SoundListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/frequencies-player',
    loader: () => import('src/view/sound/SoundPlayer'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/vip-wellness-plan',
    loader: () =>
      import('src/view/wellnessCheck/WellnessCheckPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/health-biometrics',
    loader: () =>
      import('src/view/healthBiometrics/HealthBiometricsPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/vip-wellness-plan/:id/intentions',
    loader: () =>
      import(
        'src/view/wellnessCheck/WellnessCheckIntentionsPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/vip-wellness-plan/:id/meditations',
    loader: () =>
      import(
        'src/view/wellnessCheck/WellnessCheckMeditationPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/vip-wellness-plan/frequencies',
    loader: () =>
      import(
        'src/view/wellnessCheck/WellnessCheckFrequenciesPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/vip-wellness-plan/results',
    loader: () =>
      import(
        'src/view/wellnessCheck/WellnessCheckResultPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/favorites',
    loader: () =>
      import('src/view/favorite/FavoriteListPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/21-day-focus',
    loader: () =>
      import(
        'src/view/affirmationProgram/AffirmationProgramListPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/courses',
    loader: () =>
      import(
        'src/view/courses/courseList'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/courses/:id',
    loader: () =>
      import(
        'src/view/courses/coursesPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/courses/:id/lesson-detail',
    loader: () =>
      import(
        'src/view/courses/courseDetail'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/21-day-focus/:id',
    loader: () =>
      import(
        'src/view/affirmationProgram/AffirmationProgramAffirmationListPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/mindful-intentions',
    loader: () =>
      import('src/view/affirmation/AffirmationListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/mindful-intentions/preview',
    loader: () =>
      import('src/view/affirmation/AffirmationDetail'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/affirmation/:id',
    loader: () =>
      import('src/view/affirmation/AffirmationViewPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/affirmation/:programSlug/:orderInList',
    loader: () =>
      import('src/view/affirmation/AffirmationViewPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/settings',
    loader: () => import('src/view/auth/ProfileViewPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/settings/profile/edit',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/settings/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/settings/manage-subscription',
    loader: () =>
      import('src/view/auth/ManageSubscription'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/pricing',
    loader: () =>
      import('src/view/memberShipOptions/MemberShipOptionsPage'),
    permissionRequired: null,
    exact: true,
  },

].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/signup/:provider',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
  {
    path: '/auth/subscription/:provider',
    loader: () =>
      import('src/view/auth/SubscriptionPage'),
  },
 
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const rokuRegisterRoutes = [
  {
    path: '/register',
    loader: () =>
      import('src/view/auth/RokuRegistrationPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const completeRegistrationRoutes = [
  {
    path: '/auth/complete-registration',
    loader: () =>
      import('src/view/auth/CompleteRegistrationPage'),
  },
].filter(Boolean);

const subscriptionRoutes = [
  {
    path: '/auth/subscription',
    loader: () => import('src/view/auth/SubscriptionPage'),
  },
 
  {
    path: '/auth/gift',
    loader: () =>
      import('src/view/giftCard/GiftCardPage'),
  },
  {
    path: '/auth/gift/checkout',
    loader: () =>
      import('src/view/giftCard/GiftCardCheckoutPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/signin-voice',
    loader: () => import('src/view/auth/SigninAlexaPage'),
  },
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  rokuRegisterRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  completeRegistrationRoutes,
  subscriptionRoutes,
  simpleRoutes,
};
