import {useHistory} from "react-router-dom"
import { i18n } from "src/i18n";

const UpgradeRequired = (props) => {
    const history = useHistory();
    const {setIsUpgradeModalVisible, isFrequency} = props;
    const handleCancel = ()=>{
        setIsUpgradeModalVisible(false)
    }
  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity modal-width"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto modal-width">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 text-center">
                <div className="mt-3 text-center">
                    <h3 className="text-2xl font-semibold text-darkGreen" id="modal-title">Upgrade Required</h3>
                    <img onClick={handleCancel}  className="absolute top-5 right-5 h-5 w-5 cursor-pointer" src="/images/Audio-cross.svg"/>
                    <div className="mt-4 text-center">
                      <p className="font-normal text-base sm:text-lg text-darkGreen">{i18n(`Please upgrade your subscription plan to use this ${isFrequency?"frequency composition":"feature"}.`)}</p>
                    </div>
                
                </div>
                <div className="px-4 py-3 flex  flex-wrap md:flex-nowrap justify-center mt-2 gap-x-2">
                  <button type="button" onClick={()=>history.push('/settings/manage-subscription')} className="focus:outline-none font-medium w-11/12 text-sm  transform rounded-full bg-darkGreen px-3 py-4 font-jakartaMedium tracking-wide text-darkGreen-200 transition-colors duration-200 hover:bg-darkGreen focus:bg-darkGreen disabled:cursor-default disabled:opacity-50 text-white mt-3 ">Upgrade</button>
                  <a href="#" onClick={handleCancel}  className=" focus:outline-none font-medium w-11/12 text-sm  transform rounded-full light-button hover:bg-darkGreen focus:bg-darkGreen px-3 py-4 font-jakartaMedium tracking-wide text-darkGreen-200 transition-colors duration-200 hover:bg-darkGreen focus:bg-darkGreen disabled:cursor-default disabled:opacity-50 text-white mt-3">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default UpgradeRequired